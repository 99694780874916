// import constants from '@/libs/utils/constants'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    GET_OPEN_TICKETS_STATS: () =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/daily-tasks/stats/tickets')
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_OPEN_TICKETS_ITEMS: (state, { params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/daily-tasks/stats/tickets/items', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_UNCONFIRMED_APPOINTMENTS_STATS: () =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/daily-tasks/stats/appointments')
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_UNCONFIRMED_APPOINTMENTS_ITEMS: (state, { params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/daily-tasks/stats/appointments/items', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_TODO_TASKS: (state, { params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/daily-tasks/todos', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    CREATE_TODO_TASK: (state, { params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/api/daily-tasks/todos', params)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPDATE_TODO_TASK: (state, { dailyTaskTodoId, params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(`/api/daily-tasks/todos/${dailyTaskTodoId}`, params)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    MARK_TODO_AS_DONE: (state, { dailyTaskTodoId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/api/daily-tasks/todos/${dailyTaskTodoId}/done`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    MARK_TODO_AS_UNDONE: (state, { dailyTaskTodoId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/api/daily-tasks/todos/${dailyTaskTodoId}/undone`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_COMMENTS: (state, { params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/daily-tasks/comments', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    CREATE_COMMENT: (state, { data }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/api/daily-tasks/comments', data)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPDATE_COMMENT: (state, { dailyTaskCommentId, data }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(`/api/daily-tasks/comments/${dailyTaskCommentId}`, data)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
  },
}
