export default {
  masterCampaignId: 6300,
  clipboardType: {
    All: -1,
    Comments: 44,
    Title: 45,
  },
  notificationType: {
    Disposable: 1,
    Progress: 2,
  },
  notificationVariant: {
    Info: 1,
    Success: 2,
    Warning: 3,
    Error: 4,
  },
  notificationImportationState: {
    Start: 1,
    Update: 2,
    FinishOnError: 3,
    FinishOnSuccess: 4,
  },
  allSelectionMode: {
    None: 0,
    Page: 1,
    All: 2,
  },
  ticketStatus: {
    Open: 1,
    Resolved: 2,
  },
  ticketFilter: {
    MyTickets: 1,
    AssignedToMe: 2,
  },
  supportTicketFilter: {
    All: 0,
    AssignedToMe: 1,
    ResolvedByMe: 2,
  },
  ticketPriority: {
    Low: 1,
    Normal: 2,
    High: 3,
  },
  ticketCategory: {
    Database: 1,
    Technology: 2,
    Administration: 3,
    Production: 4,
  },
  ticketWatchLevel: {
    None: 0,
    WatchComments: 1,
    WatchAll: 2,
  },
  ticketCommentVisibility: {
    Internal: 1,
    Public: 2,
  },
  userStatus: {
    Enabled: 1,
    Disabled: 2,
  },
  roleStatus: {
    Admin: 1,
    QA: 50,
    Agent: 2,
    Client: 38,
  },
  projectStatus: {
    Open: 1,
    Completed: 2,
  },
  projectTaskStatus: {
    Todo: 1,
    Done: 2,
  },
  projectTaskPriority: {
    Normal: 1,
    Important: 2,
  },
  clientAppointmentAction: {
    None: 0,
    NoShow: 1,
    NewDate: 2,
    RDVConfirmedByProspect: 3,
    Show: 4,
  },
  meetingFilter: {
    all: 0,
    onlyDisponibilities: 1,
    onlyMeetings: 2,
  },
  contractModality: {
    Meetings: 1,
    Hours: 2,
  },
  contractFilter: {
    Active: 1,
    Inactive: 2,
    Archived: 3,
  },
  // Binary flags
  invoiceMeetingStateFilter: {
    registered: 1,
    available: 2,
    all: 3,
  },
  // Binary flags
  invoiceMeetingStatusFilter: {
    obtained: 1,
    newDate: 2,
    confirmed: 4,
    factured: 8,
    all: 15,
  },
  invoiceStatus: {
    Open: 1,
    Close: 2,
  },
  bonusStatusFilter: {
    all: 0,
    paid: 1,
    unpaid: 2,
  },
  supportTicketsCommentVisibility: {
    Internal: 1,
    ReceivedByEmail: 2,
    SentByEmail: 4,
  },
  clientStatusFilter: {
    active: 1,
    inactive: 2,
    all: 3,
  },
  clientContractTypeFilter: {
    meetings: 1,
    hours: 2,
    all: 3,
  },
  clientFileOrigin: {
    client: 0,
    invoice: 1,
    invoiceComment: 2,
  },
  dailyTaskTodoStatusFilter: {
    all: 0,
    todo: 1,
    completed: 2,
  },
  dailyTaskTodoFrequencyType: {
    daily: 1,
    weekly: 2,
    monthly: 3,
    yearly: 4,
  },
}
