import useJwt from '@/auth/jwt/useJwt'
import ability from '@/libs/acl/ability'
import { initialAbility } from '@/libs/acl/config'
import Vue from 'vue'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return !!(
    localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  )
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const hasAbility = (resource, action = 'manage') => {
  try {
    const userData = getUserData()
    if (!userData?.ability || !Array.isArray(userData.ability)) {
      return false
    }
    // Check if user has the required ability
    return userData.ability.some(rule => {
      // Check for exact match
      if (rule.action === action && rule.subject === resource) {
        return true
      }

      // Check for wildcard actions ('manage' typically implies all actions)
      if (rule.action === 'manage' && rule.subject === resource) {
        return true
      }

      return false
    })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error checking user access:', error)
    return false
  }
}

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate
 * to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function
 *       can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'agent' || userRole === 'admin' || userRole === 'qa') return '/'
  if (userRole === 'client') return '/client-portal'

  return '/login'
}

export const getRole = roleId => {
  if (roleId === undefined) {
    // eslint-disable-next-line no-param-reassign
    roleId = getUserData().roleId
  }
  switch (roleId) {
    case 1:
      return 'admin'
    case 50:
      return 'qa'
    case 2:
      return 'agent'
    case 38:
      return 'client'
    default:
      return 'client'
  }
}

export const getRoleId = role => {
  switch (role) {
    case 'admin':
      return 1
    case 'qa':
      return 50
    case 'agent':
      return 2
    case 'client':
      return 38
    default:
      return 38
  }
}

export const getRoles = () => ['Admin', 'QA', 'Agent', 'Client']

export const getLanguageId = language => {
  switch (language) {
    case 'FR':
      return 42
    case 'EN':
      return 43
    default:
      return 42
  }
}

export const getCampaignTypeId = campaign => {
  switch (campaign) {
    case 'Meetings':
      return 47
    case 'BankedHour':
      return 48
    default:
      return 47
  }
}

export const abilityResolver = (role, permissions = []) => {
  // TODO: Resolve ability based on role properly... aka make it so that agent dont see admin
  let abilities = []
  switch (role) {
    case 'admin':
      abilities = [
        {
          action: 'manage',
          subject: 'all',
        },
        // "administration" is implied by "all"
      ]
      break
    case 'qa':
      abilities = [
        ...abilityResolver('agent'),
        {
          action: 'read',
          subject: 'qa',
        },
      ]
      break
    case 'agent':
      abilities = [
        {
          action: 'read',
          subject: 'home',
        },
        {
          action: 'read',
          subject: 'campaigns',
        },
        {
          action: 'read',
          subject: 'worksheet',
        },
        {
          action: 'read',
          subject: 'settings',
        },
        {
          action: 'read',
          subject: 'tickets',
        },
        {
          action: 'read',
          subject: 'my-statistics',
        },
        {
          action: 'read',
          subject: 'my-bonuses',
        },
        /* Agent Weekly Schedule is not used anymore
        {
          action: 'read',
          subject: 'agentWeeklySchedule',
        }, */
      ]
      break
    case 'client':
      abilities = [
        {
          action: 'read',
          subject: 'client-portal',
        },
        {
          action: 'read',
          subject: 'settings',
        },
      ]
      break
    default:
      abilities = []
      break
  }

  // Add permissions
  for (let i = 0; i < permissions.length; i += 1) {
    abilities.push({
      action: 'manage',
      subject: permissions[i],
    })
  }

  return abilities
}

export const closeSession = redirectCallback => {
  // Remove userData from localStorage
  // ? You just removed token from localStorage. If you like, you can also make API call
  // ? to backend to blacklist used token
  localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
  localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

  // Remove userData from localStorage
  localStorage.removeItem('userData')

  // Reset ability
  ability.update(initialAbility)

  // Stop the hubs
  if (Vue.prototype.$notificationHub.isStarted()) {
    Vue.prototype.$notificationHub.stop()
  }
  if (Vue.prototype.$punchHub.isStarted()) {
    Vue.prototype.$punchHub.stop()
  }
  if (Vue.prototype.$actionHub.isStarted()) {
    Vue.prototype.$actionHub.stop()
  }

  // Clear filters
  Vue.prototype.$store.dispatch('followUpProspects/CLEAR_FILTERS')
  Vue.prototype.$store.dispatch('prospects/CLEAR_FILTERS')

  if (redirectCallback) {
    redirectCallback()
  }
}
