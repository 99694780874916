import Vue from 'vue'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    GET_LOGS: (state, paramsLogs) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/worksheets-logs', { params: paramsLogs })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
  },
}
